import React from "react"
import { graphql } from "gatsby"
import { Dialog } from "@reach/dialog"
import { Router, Location } from "@reach/router"

// Components
import FunctionProfileCard from "../components/FunctionProfileCard"
import FunctionProfiles from "../components/function-profiles"
import PageLayout from "../components/PageLayout"

function Routes(props: any) {
  return (
    <Router {...props}>
      <FunctionProfiles path="/profiles" data={props.data} />
      <FunctionProfileCard
        path="/profiles/:expertise/:level"
        data={props.data}
      />
    </Router>
  )
}

const App = ({ data }: any) => {
  return (
    <Location>
      {({ location, navigate }) => {
        const oldLocation =
          (location.state && (location.state as any).oldLocation) || null

        return (
          <PageLayout seoTitle={"Function profiles"}>
            <Routes
              location={oldLocation != null ? oldLocation : location}
              data={data}
            />
            <Dialog
              aria-label="function"
              isOpen={oldLocation != null}
              onDismiss={() => {
                navigate(oldLocation.pathname)
              }}
            >
              <Routes location={location} data={data} />
            </Dialog>
          </PageLayout>
        )
      }}
    </Location>
  )
}
export default App

export const query = graphql`
  query GetAllFunctionProfiles {
    allProfiles: allPrismicProfile {
      expertises: nodes {
        id
        data {
          job_title {
            document {
              data {
                name
                abbreviation {
                  text
                }
              }
            }
          }
          description {
            text
          }
          skillsPerLevel: body {
            primary {
              level {
                document {
                  data {
                    name
                    abbreviation
                    description {
                      text
                    }
                    image {
                      alt
                      url
                    }
                  }
                }
              }
            }
            skills: items {
              skill_name
              skill_description {
                text
              }
            }
          }
        }
      }
    }
    allLevelQualities: allPrismicLevelQuality {
      nodes {
        ...LevelQualities
      }
    }
  }
`
