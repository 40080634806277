import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Location } from "@reach/router"

// Styling
import colors from "../styles/colors"

// Components
import FunctionsList from "./FunctionsList"

// Types
import { IExpertise } from "../types"

interface IProps {
  expertises: IExpertise[]
}

const LinkMatrix: React.FunctionComponent<IProps> = ({
  expertises,
}: IProps) => {
  const profileLevels = Object.keys(expertises[0].levels).map(
    i => expertises[0].levels[i]
  )

  return (
    <Location>
      {({ location }) => {
        const parsedLocation = JSON.parse(JSON.stringify(location))

        return (
          <>
            <TableWrapper>
              <Matrix cellSpacing={20}>
                <tbody>
                  <TR>
                    <th></th>
                    {expertises.map(expertise => (
                      <TH key={expertise.id}>{expertise.name}</TH>
                    ))}
                  </TR>
                  {profileLevels.map(level => (
                    <TR key={level.abbreviation}>
                      <td>
                        <Level>{level.name} -</Level>
                      </td>
                      {expertises.map(expertise => (
                        <TD key={expertise.id}>
                          <Link
                            state={{
                              oldLocation: parsedLocation,
                            }}
                            to={`/profiles/${expertise.name.replace(
                              / /g,
                              "-"
                            )}/${[level.abbreviation]}`}
                          >
                            <strong>
                              {level.abbreviation}. {expertise.abbreviation}
                            </strong>
                          </Link>
                        </TD>
                      ))}
                    </TR>
                  ))}
                </tbody>
              </Matrix>
            </TableWrapper>
            <FunctionsList profileLevels={profileLevels} />
          </>
        )
      }}
    </Location>
  )
}

export { LinkMatrix }

const TableWrapper = styled.div`
  overflow-x: auto;
`

const Matrix = styled.table`
  margin: 3em 0;
  padding: 0 1em 1em;
  width: 100%;
  border-collapse: collapse;
`

const TR = styled.tr`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${colors.black};
  }
  padding: 5px;
`

const Level = styled.strong`
  display: inline-block;
  width: 70px;
  text-align: right;
  margin-right: 1em;
  font-weight: 100;
`

const TH = styled.th`
  max-width: 100%;
  font-weight: 100;
  white-space: nowrap;
  padding: 0 1em 1em;
  text-align: left;
`

const TD = styled.td`
  max-width: 100%;
  white-space: nowrap;
  padding: 10px;
  a {
    color: ${colors.black};
    font-size: 1.8em;
    font-weight: 100;
    cursor: pointer;
    transition: 0.3s;
    :hover {
      color: ${colors.accent1};
      text-decoration: none;
    }
  }
`
