import React from "react"
import { Link } from "gatsby"
import styled, { keyframes } from "styled-components"

// Styling
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"
import svgStyles from "../styles/svgStyles"
import { mq } from "../styles/utils/mq"

// Components
import Footer from "./Footer"
import BackIcon from "../icons/BackIcon"

// Types
import { ILevel, ISkill, IImage } from "../types"

interface IPrincinple {
  name: string
  description: string
  image: IImage
  levels: {
    [key: string]: {
      data: ILevel
      description: string
    }
  }
}

interface IProps {
  profileName: string
  level: ILevelDetails
  skills: ISkill[]
  principles: IPrincinple[]
  description: string
}

interface ISection {
  theme: string
}

interface ILevelDetails {
  abbreviation: string
  name: string
  description: {
    text: string
  }
}

const FunctionProfileCard: React.FunctionComponent<IProps> = ({
  profileName,
  level,
  skills,
  principles,
  description,
}: IProps) => {
  return (
    <Card>
      <CardHeader>
        <Link to={"/profiles"}>
          <BackIcon color="black" />
        </Link>
        <CardHeaderTitle>
          &#8212; {level.name} <strong>{profileName}</strong>
        </CardHeaderTitle>
        <CardHeaderIntro>{description}</CardHeaderIntro>
      </CardHeader>
      <SectionWrapper>
        <SkillsSection theme="light">
          <SectionTitle>skills</SectionTitle>
          <NumberedList>
            {skills.map((skill, index) => (
              <ListItem key={index}>
                <ListItemTitle>{skill.name}</ListItemTitle>{" "}
                <ListItemText>{skill.description}</ListItemText>
              </ListItem>
            ))}
          </NumberedList>
        </SkillsSection>
        <QualitiesSection theme="dark">
          <SectionTitle>qualities</SectionTitle>
          <NumberedList>
            {principles.map((principle: any, index) => (
              <ListItem key={index}>
                <ListItemTitle>{principle.name}</ListItemTitle>{" "}
                <ListItemText>{principle.description}</ListItemText>
              </ListItem>
            ))}
          </NumberedList>
        </QualitiesSection>
      </SectionWrapper>
      <Footer color={colors.white} background={colors.accent1} />
    </Card>
  )
}

const getLevelDescription = (principlesPerLevel: any, level: string) => {
  const principleForLevel = principlesPerLevel.find(
    (dnaLevelItem: any) =>
      dnaLevelItem.level &&
      dnaLevelItem.level.document[0].data.abbreviation.toLowerCase() === level
  )

  if (principleForLevel) {
    return principleForLevel.description.text
  } else {
    return ""
  }
}

const ConnectedFunctionProfileCard = ({ data, expertise, level }: any) => {
  if (!data || !data.allProfiles) {
    return null
  }

  const functionProfile = data.allProfiles.expertises.find(
    (expertiseItem: any) =>
      expertise.replace(/-/g, " ").toLowerCase() ===
      expertiseItem.data.job_title.document[0].data.name.toLowerCase()
  )

  const formattedFunctionProfile = {
    id: functionProfile.id,
    name: functionProfile.data.job_title.document[0].data.name,
    description: functionProfile.data.description.text,
    skills: functionProfile.data.skillsPerLevel
      .filter(
        (item: any) =>
          item.primary.level.document[0].data.abbreviation.toLowerCase() ===
          level.toLowerCase()
      )
      .map((skillLevel: any) =>
        skillLevel.skills.map((skillLevelItem: any) => ({
          name: skillLevelItem.skill_name,
          description: skillLevelItem.skill_description.text,
        }))
      )
      .flat(),
  }

  const teamDNA = data.allLevelQualities.nodes.map((item: any) => ({
    id: item.id,
    name: item.data.name,
    principles: item.data.dnaPrinciples.map((principle: any) => ({
      name: principle.dnaPrinciple.name,
      description: principle.dnaPrinciple.description.text,
      levelDescription: getLevelDescription(
        principle.dnaPrinciplePerLevel,
        level.toLowerCase()
      ),
    })),
    desc: item,
  }))

  const levelDescription = data.allProfiles.expertises[0].data.skillsPerLevel.find(
    (dnaDesc: any) =>
      dnaDesc.primary.level.document[0].data.abbreviation === level
  ).primary.level.document[0].data.description.text

  const levelData = data.allLevelQualities.nodes[0].data.dnaPrinciples[0].dnaPrinciplePerLevel.find(
    (dnaLevel: any) => dnaLevel.level.document[0].data.abbreviation === level
  ).level.document[0].data

  return (
    <FunctionProfileCard
      profileName={formattedFunctionProfile.name}
      skills={formattedFunctionProfile.skills}
      principles={teamDNA[0].principles}
      level={levelData}
      description={levelDescription}
    />
  )
}

export { FunctionProfileCard }

export default ConnectedFunctionProfileCard

const Card = styled.article`
  background-color: ${colors.smoke};
  display: block;

  ${mq.from.XXXL`
    font-size: 1.3em;
  `}
`

const CardHeader = styled.div`
  padding: 5em 3em;
  position: relative;
  background-color: ${colors.accent1};

  svg {
    ${svgStyles.regularSVG};
    position: absolute;
    left: 35px;
    font-size: 1.5em;
    top: 35px;
    transition: 0.3s;
    fill: ${colors.black};

    &:hover {
      fill: ${colors.white};
    }
  }

  ${mq.from.M`
    padding: 3em 5em;
  `}
`

const CardHeaderTitle = styled.h2`
  ${textStyles.headingXL};
  max-width: 600px;
  margin: auto;
  font-size: 2.3em;

  ${mq.from.M`
    font-size: 3.3em;
  `}

  ${mq.from.XL`
    max-width: 850px;
  `}

  strong {
    color: ${colors.white};
  }
`

const CardHeaderIntro = styled.p`
  ${textStyles.body};
  margin: 1em auto 0em auto;
  max-width: 600px;
  font-size: 1.2em;

  ${mq.from.S`
    font-size: 1.1em;
  `}

  ${mq.from.XL`
    max-width: 850px;
  `}

  strong {
    color: ${colors.accent1};
  }
`

const scalePlay = keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
`

const SectionTitle = styled.h3`
  font-size: 3.5em;
  text-transform: capitalize;
  text-align: center;
  position: relative;
  margin-bottom: 2em;

  &:before,
  &:after {
    position: absolute;
    transition: 1s;

    ${mq.from.L`
    content: "";
    `}
  }
`

const SectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${mq.from.L`
    flex-direction: row;
  `}
  /* fun animation on the plus icon */
  &:hover {
    ${SectionTitle}::before, ${SectionTitle}::after {
      animation: ${scalePlay} 2s linear 2;
    }
  }
`

const ListItemTitle = styled.h4`
  display: block;
  width: 100%;
  letter-spacing: 1px;
  color: ${colors.black};
  margin-top: 0;
  margin-bottom: 0.5em;
`

const ListItemText = styled.span`
  display: block;
  width: 100%;
`

const ListItem = styled.li`
  display: flex;
  margin-bottom: 1.5em;
  font-weight: 700;
  flex-direction: column;

  strong {
    margin-top: 0;
    margin-bottom: 0.5em;
  }

  ${ListItemText} {
    font-weight: normal;
  }

  ${mq.from.L`
    flex-direction: row;
  `}
`

const SectionBlock = styled.section<ISection>`
  width: 100%;
  padding: 30px;
  ${({ theme }) =>
    theme === "dark"
      ? `background-color: ${colors.black}`
      : `background-color: ${colors.white}`};
  ${({ theme }) =>
    theme === "dark" ? `color: ${colors.white}` : `color: ${colors.black}`};
  width: calc(100% - 60px);

  ${SectionTitle} {
    ${({ theme }) =>
      theme === "dark" ? `color: ${colors.white}` : `color: ${colors.black}`};
  }

  ${mq.from.M`
    padding: 60px;
    width: calc(100% - 120px);
  `}
`

const QualitiesSection = styled(SectionBlock)`
  ${SectionTitle} {
    &:before,
    &:after {
      background-color: ${colors.white};
      left: -60px;
    }

    &:before {
      width: 60px;
      height: 40px;
      top: 0;
    }

    &:after {
      width: 20px;
      height: 120px;
      top: -40px;
    }
  }

  ${ListItemTitle} {
    color: ${colors.white};
  }

  ${ListItemText} {
    color: ${colors.white};
  }
`

const SkillsSection = styled(SectionBlock)`
  ${SectionTitle} {
    &:before,
    &:after {
      background-color: ${colors.black};
      right: -60px;
    }

    &:before {
      width: 60px;
      height: 40px;
      top: 0;
    }

    &:after {
      width: 20px;
      height: 120px;
      top: -40px;
      z-index: 100;
    }
  }
`

const NumberedList = styled.ol`
  list-style-type: decimal;
  margin: 0;
  padding: 0 1.2em;
`
