import React from "react"

// Styling
import colors from "../styles/colors"

const BackIcon: React.FC<{ color: string }> = ({
  color = colors.black,
  ...props
}) => (
  <svg viewBox="0 0 40 36" {...props} fill={color}>
    <path d="M18 0L0 18l18 18 6-6-7-7h23V13H17l7-7-6-6z" />
  </svg>
)

export default BackIcon
