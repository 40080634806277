import React from "react"

// Components
import SEO from "./seo"
import { LinkMatrix } from "./LinkMatrix"

// Types
import { IExpertise, ISkillPerLevel } from "../types"

interface IProps {
  children: React.ReactNode
}

const FunctionProfilesPage = ({ children }: IProps) => {
  return (
    <>
      <SEO title="Function Profiles" />
      {children}
    </>
  )
}

const ConnectedFunctionProfilesPage = ({ data }: any) => {
  if (!data.allProfiles) {
    return null
  }

  if (
    data.allProfiles.expertises !== undefined &&
    data.allProfiles.expertises !== null &&
    data.allProfiles.expertises[0].data.skillsPerLevel[0].primary.level
      .document !== null
  ) {
    const expertises: IExpertise[] = data.allProfiles.expertises.map(
      (expertise: any) => ({
        id: expertise.id,
        name: expertise.data.job_title.document[0].data.name,
        abbreviation:
          expertise.data.job_title.document[0].data.abbreviation.text,
        description: expertise.data.description.text,
        levels: expertise.data.skillsPerLevel.reduce(
          (accum: any, skillLevel: ISkillPerLevel) => ({
            ...accum,
            [skillLevel.primary.level.document[0].data.abbreviation]:
              skillLevel.primary.level.document[0].data,
          }),
          {}
        ),
      })
    )

    return (
      <FunctionProfilesPage>
        <LinkMatrix expertises={expertises} />
      </FunctionProfilesPage>
    )
  } else {
    return null
  }
}

export default ConnectedFunctionProfilesPage
